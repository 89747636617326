<template>
    <!-- in ios add pt-10 instead of pt-0-->
    <vue-loadmore
        :on-refresh="refresh_mr_tasks"
        :finished="false"
        style="width: 100%; direction: ltr"
        :pulling-text="$tr('Refresh')"
        :loosing-text="$tr('Refresh')"
        :refresh-text="`${$tr('Loading')}`"
        :loading-text="`${$tr('Loading')}`"
        :finished-text="$tr('Done')"
        :success-text="$tr('Done')"
        class="fill-height"
    >
        <pull-to-refresh-banner ref="banner"></pull-to-refresh-banner>
        <v-container fluid class="px-0 pt-4">
            <v-row class="px-0">
                <v-col cols="12" class="px-0"
                >
                    <v-fade-transition mode="out-in" group
                        class="d-flex align-start flex-direction-row"
                    >
                        <v-container v-show="show_tasks" :key="'main'" class="pa-0">
                            <component
                                :style="`direction: ${
                                    $vuetify.rtl ? 'rtl' : 'ltr'
                                };`"
                                :is="
                                    mapper[content.status] || mapper['no_tasks']
                                "
                                @refresh="refresh_mr_tasks"
                                v-bind="content"
                            ></component>
                        </v-container>

                        <template>
                            <v-container
                                v-if="show_skeleton"
                                :key="'skeleton'"
                                class="px-8"
                                fluid
                            >
                                <v-row class="mb-2" :key="'skeleton-1'">
                                    <v-skeleton-loader
                                        :loading="true"
                                        width="100%"
                                        type="article, actions"
                                    ></v-skeleton-loader>
                                </v-row>
                                <v-row class="mb-2" :key="'skeleton-2'">
                                    <v-skeleton-loader
                                        :loading="true"
                                        width="100%"
                                        type="article, actions"
                                    ></v-skeleton-loader>
                                </v-row>
                                <v-row class="mb-2" :key="'skeleton-3'">
                                    <v-skeleton-loader
                                        :loading="true"
                                        width="100%"
                                        type="article, actions"
                                    ></v-skeleton-loader>
                                </v-row>
                            </v-container>
                        </template>
                    </v-fade-transition>
                </v-col>
            </v-row>
        </v-container>
    </vue-loadmore>
</template>

<script>
import { sync } from "vuex-pathify";
import Api from "@/api/api";
export default {
    data() {
        return {
            show_banner: false,
            show_skeleton: false,
            show_tasks: false,
            content: {},
            mapper: {
                no_tasks: "work-day-no-tasks",
                dayoff: "day-off",
                running: "work",
            },
        };
    },
    async mounted() {
        await this.refresh_mr_tasks(null, true, false);
    },
    computed: {
        ...sync("app", ["items"]),
        ...sync("user", {
            user: "user",
            loading_cover: "visualization@loading_cover",
        }),
    },
    methods: {
        async set_loading() {
            this.show_tasks = false;
            this.show_skeleton = false;
            await this.$sleep(300);
            this.show_skeleton = true;
            await this.$sleep(200);
        },
        async clear_loading() {
            this.show_skeleton = false;
            this.show_tasks = false;
            await this.$sleep(100);
            this.show_tasks = true;
        },
        async refresh_mr_tasks(done, show_banner = false, now = true) {
            await this.set_loading();
            await this.refresh(now);
            await this.clear_loading();
            if (show_banner) {
                this.$refs.banner.view_banner();
            }
            done?.();
        },
        async refresh(now) {
            let response;
            try {
                response = await Api.post("task/fetch/my_tasks", null, {
                    sinceMins: 30,
                    now: now,
                });
                this.content = response.data.result;
            } catch (error) {
                console.log("err", error);
            }
        },
    },
    components: {
        WorkDayNoTasks: () =>
            import(
                /* webpackChunkName: "workday_no_tasks" */
                "./workday_no_tasks"
            ),
        DayOff: () =>
            import(
                /* webpackChunkName: "workday_no_tasks" */
                "./dayoff"
            ),
        Work: () =>
            import(
                /* webpackChunkName: "work" */
                "./work"
            ),
        PullToRefreshBanner: () =>
            import("../../Admin/Home/components/pullToRefreshBanner/Index.vue"),
    },
};
</script>

<style>
</style>